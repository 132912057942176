import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function ChangeStatus({
  show,
  setShow,
  pedido,
  getSolicitacoes,
}) {
  const handleClose = () => setShow(false);
  const [status, setStatus] = useState(pedido[0].status);
  const statusList = ['Enviada', 'Em separação', 'Entregue'];
  const exclusiveClass = new exclusive();
  const handleChange = async () => {
    await exclusiveClass.changeStatus(pedido[0].idHeader, status);
    if (exclusiveClass.statusIsChanged) {
      setShow(false);
      getSolicitacoes();
      return toast.success("Status do Pedido alterado com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setShow(false);
    return toast.error(
      "Erro ao alterar o Status do Pedido. Tente novamente mais tarde.",
      {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };
  return (
    <>
      <Modal size="md" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alterar Status do Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row className="mb-3">
            <Col xs={6} md={6}>
              <Form.Group as={Col}>
                <Form.Label>Categoria do Produto</Form.Label>
                <Form.Select
                  size="md"
                  aria-label="Default select example"
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {statusList.map((statusName) => {
                    return (
                      <option
                        selected={status === statusName ? "true" : ""}
                        key={statusName}
                        value={statusName}
                      >
                        {statusName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChange}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
