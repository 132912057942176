import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import exclusive from "../../classes/exclusive";
import CartItem2 from "../../components/cartItem/cartItem2";
import { Button } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import './print.css'

export default function ShowKit({ show2, setShow2, pedido }) {
  const handleClose = () => setShow2(false);
  const [itensPedido, setItensPedido] = useState([]);
  const exclusiveClass = new exclusive();
  useEffect(() => {
    getProductsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getProductsInfo = async () => {
    await exclusiveClass.getProductsInfo(pedido.idHeader);
    const produtos = exclusiveClass.productsInfo;
    setItensPedido(produtos);
  };
  const componentRef = React.useRef(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <Button>Imprimir</Button>;
  }, []);
  return (
    <>
      <Modal size="xl" centered show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Itens do Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={componentRef} className="items">
          {itensPedido.map((item) => (
            <CartItem2 data={item} />
          ))}
        </Modal.Body>
        <Modal.Footer>
          <div className="cart-price">
            {pedido.valor.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </div>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </Modal.Footer>
      </Modal>
    </>
  );
}
