import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function ChangeUser({ show, setShow, user, getUsers }) {
  const handleClose = () => setShow(false);
  const [name, setName] = useState(user.userName);
  const [email, setEmail] = useState(user.userEmail);
  const [limit, setLimit] = useState(
    user.limitValue.toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    })
  );
  const [limitUnFormated, setLimitUnFormated] = useState(user.limitValue);
  const [quant, setQuant] = useState(user.limitQuant);
  const [admin, setAdmin] = useState(
    user.userFunction === "Administrador" ? true : false
  );
  const exclusiveClass = new exclusive();
  const handleChange = async () => {
    const admin_aux = admin ? "Administrador" : "Revendedor";
    await exclusiveClass.changeUser(name, email, admin_aux, user.userId, quant, limitUnFormated);
    if (exclusiveClass.userIsChanged) {
      setShow(false);
      getUsers();
      return toast.success("Dados do usuário alterados com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setShow(false);
    return toast.error(
      "Erro ao alterar dados do usuário. Tente novamente mais tarde.",
      {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };
  const handleClick = (value) => {
    if (admin) {
      setAdmin("");
    } else {
      setAdmin("Administrador");
    }
  };
  const mascaraMoeda = (event) => {
    const onlyDigits = event
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    setLimit(maskCurrency(digitsFloat));
    let valor2 = maskCurrency(digitsFloat);
    valor2 = valor2.replace("R$", "");
    valor2 = valor2.replace(".", "");
    valor2 = valor2.replace(",", ".");
    valor2 = valor2.trim();
    setLimitUnFormated(valor2);
  };

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }
  return (
    <>
      <Modal size="md" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alterar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nome do Usuário</Form.Label>
              <Form.Control
                required
                value={name}
                maxLength={100}
                onChange={(e) => setName(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                required
                value={email}
                maxLength={100}
                onChange={(e) => setEmail(e.target.value)}
                size="md"
                type="text"
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Label>Valor limite por Solicitação</Form.Label>
            <Col xs={6} md={4}>
              <Form.Group as={Col}>
                <Form.Control
                  required
                  value={limit}
                  maxLength={15}
                  onChange={(e) => mascaraMoeda(e.target.value)}
                  size="md"
                  type="text"
                  placeholder="R$0,00"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label>Número de Peças por Solicitação</Form.Label>
            <Col xs={6} md={4}>
              <Form.Group as={Col}>
                <Form.Control
                  required
                  value={quant}
                  onChange={(e) => setQuant(e.target.value)}
                  size="md"
                  type="number"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Check // prettier-ignore
                type="checkbox"
                id="adm"
                label="Usuário Administrador"
                checked={admin ? true : ""}
                onClick={handleClick}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChange}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
