import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function EditCategory({
  show,
  setShow,
  getCategories,
  category,
  categories,
}) {
  const exclusiveClass = new exclusive();
  const [categoryName, setCategoryName] = useState(category.typeName);
  const handleClose = () => {
    setShow(false);
  };
  const handleSalve = async () => {
    if (!categoryName || categoryName.length < 3) {
      return toast.warn("Preencher o nome da categoria corretamente.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (categories.find((o) => o.typeName === categoryName && o.typeName !== category.typeName)) {
      return toast.warn("Já existe uma categoria com este nome.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    await exclusiveClass.changeCategory(category.typeId, categoryName);
    if (exclusiveClass.categoryIsChanged) {
      getCategories();
      setShow(false);
      return toast.success("Categoria Modificada.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    toast.error("Erro ao modificar categoria. Tente novamente mais tarde.", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
    setShow(false);
  };
  const handleDelete = async () => {
    await exclusiveClass.deleteCategory(category.typeId);
    if (exclusiveClass.categoryIsDeleted) {
      getCategories();
      setShow(false);
      return toast.success("Categoria Deletada com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    toast.error("Erro ao Deletar categoria. Tente novamente mais tarde.", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
    setShow(false);
  };
  return (
    <>
      <Modal size="sm" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nome da Categoria</Form.Label>
              <Form.Control
                required
                value={categoryName}
                maxLength={25}
                onChange={(e) => setCategoryName(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Deletar
          </Button>
          <Button variant="primary" onClick={handleSalve}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
