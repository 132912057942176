import React from "react";
import "./cartItem2.css";
import propTypes from "prop-types";

function CartItem2({ data }) {
  
  return (
    <section className="cart-item">
      <img
        src={data.productImage}
        alt="imagem do produto"
        className="cart-item-image"
      />

      <div className="cart-item-content">
        <h3 className="cart-item-title">{data.productName}</h3>
        <h3 className="cart-item-price">
          {data.productPrice.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </h3>
        {data.productSizess === 'undefined' ? '' : <p className="cart-item-info">Tamanho: {data.productSizess}</p>}
        {data.productColorss === 'undefined' ? '' : <p className="cart-item-info">Cor: {data.productColorss}</p>}
      </div>
    </section>
  );
}

export default CartItem2;

CartItem2.propTypes = {
  data: propTypes.object,
}.isRequired;
