import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import exclusive from "../../classes/exclusive";
import Loading from "../../components/loading/loading";
import Button from "react-bootstrap/Button";
import ChangeUser from "./changeUser";
import { ToastContainer } from "react-toastify";
import ChangePass from "./changePass";
import AddUser from "./adduser";
import ConfirmDel from "./deleteuser";
import validations from "../../classes/validations";
import CategoryLimit from "./categoryLimit";

export function Users() {
  const validationClass = new validations();
  const exclusiveClass = new exclusive();
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    checkAdminAcess();
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkAdminAcess = async () => {
    await validationClass.checkAdmin();
  };
  const getUsers = async () => {
    await exclusiveClass.getUsers();
    setUsers(exclusiveClass.users);
    setLoading(false);
  };
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "icone",
      headerName: "",
      renderCell: ({ row: { userId } }) => {
        return (
          <>
            <Button
              variant="info"
              className="pointer"
              onClick={() => handleEdit(users, userId)}
            >
              Modificar
            </Button>
          </>
        );
      },
      width: 140,
    },
    { field: "userName", headerName: "Nome do Usuário", width: 280 },
    { field: "userEmail", headerName: "E-mail", width: 280 },
    {
      field: "icone2",
      headerName: "",
      renderCell: ({ row: { userId } }) => {
        return (
          <>
            <Button
              className="pointer"
              onClick={() => handlePass(users, userId)}
            >
              Alterar Senha
            </Button>
          </>
        );
      },
      width: 165,
    },
    {
      field: "icone3",
      headerName: "",
      renderCell: ({ row: { userId } }) => {
        return (
          <>
            <Button
              variant="danger"
              className="pointer"
              onClick={() => handleDelete(users, userId)}
            >
              Excluir
            </Button>
          </>
        );
      },
      width: 115,
    },
  ];
  const handleEdit = (usuarios, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
      usuarios.find((row) => row.userId === id)
    );
    setUser(selectedRowsData[0]);
    setShow(true);
  };
  const handleDelete = (usuarios, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
      usuarios.find((row) => row.userId === id)
    );
    setUser(selectedRowsData[0]);
    setShow4(true);
  };
  const handlePass = (usuarios, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
      usuarios.find((row) => row.userId === id)
    );
    setUser(selectedRowsData[0]);
    setShow2(true);
  };
  const handleCreate = () => setShow3(true);
  return (
    (loading && <Loading />) || (
      <Container className="padding-b">
        <Box m="20px">
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
            m="10px 0"
          >
            <Header
              title="Administração de Usuários"
              subtitle="Crei, edite e elimine usuário aqui."
            />
          </Box>
        </Box>
        <Grid
          className="sombra"
          m="20px 0 0 0"
          p="10px"
          xs={12}
          backgroundColor={colors.primary[100]}
        >
          <Button variant="primary" onClick={handleCreate}>Criar Usuário</Button>
          <Box
            m="8px 0 0 0"
            width="100%"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
            }}
          >
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={users}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              getRowId={(row) => row.userId}
            />
          </Box>
        </Grid>
        <CategoryLimit users={users} />
        {show && (
          <ChangeUser
            show={show}
            setShow={setShow}
            user={user}
            getUsers={getUsers}
          />
        )}
        {show2 && (
          <ChangePass show2={show2} setShow2={setShow2} userId={user.userId} />
        )}
        {show3 && (<AddUser show3={show3} setShow3={setShow3} getUsers={getUsers} />)}
        {show4 && (<ConfirmDel show4={show4} setShow4={setShow4} user={user} getUsers={getUsers} />)}
        <ToastContainer autoClose={3000} position="top-right" />
      </Container>
    )
  );
}
