import React from "react"
import './footer.css'
import { MDBIcon } from 'mdb-react-ui-kit';

const Footer = () => <footer className="page-footer font-small blue pt-4 footer">

    <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
            <span>Fique conectado em nossas redes sociais:</span>
        </div>

        <div>
            <a href='https://instagram.com/louzanjoias' target="_blank" className='me-4 text-reset' rel="noreferrer">
                <MDBIcon fab icon="facebook-f" />
            </a>
            {/* <a href='https://instagram.com/louzanjoias' className='me-4 text-reset'>
                <MDBIcon fab icon="twitter" />
            </a> */}
            {/* <a href='/' className='me-4 text-reset'>
                <MDBIcon fab icon="google" />
            </a> */}
            <a href='https://www.instagram.com/louzanjoias/' target="_blank" className='me-4 text-reset' rel="noreferrer">
                <MDBIcon fab icon="instagram" />
            </a>
            {/* <a href='/' className='me-4 text-reset'>
                <MDBIcon fab icon="linkedin" />
            </a>
            <a href='/' className='me-4 text-reset'>
                <MDBIcon fab icon="github" />
            </a> */}
        </div>
    </section>

    <div className="footer-copyright text-center py-3">© 2024 Louzan Joias | Created By:
        <a href="https://pitterrs.com.br/">pitterrs@gmail.com</a>
    </div>

</footer>

export default Footer;