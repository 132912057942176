import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function ChangePass({ show2, setShow2, userId }) {
  const handleClose = () => setShow2(false);
  const [pass, setPass] = useState('');
  const exclusiveClass = new exclusive();
  const handleChange = async () => {
    await exclusiveClass.changePass(pass, userId);
    if (exclusiveClass.passIsChanged) {
      setShow2(false);
      return toast.success("Senha do usuário alterado com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    return toast.error("Erro ao alterar senha do usuário. Tente novamente mais tarde.", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
  };
  return (
    <>
      <Modal size="md" centered show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Alterar Senha do Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Senha do Usuário</Form.Label>
              <Form.Control
                required
                value={pass}
                maxLength={10}
                onChange={(e) => setPass(e.target.value)}
                size="md"
                type="password"
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChange}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
