import axios from "axios";

class exclusive {
  constructor() {
    this.products = [];
    this.allProducts = [];
    this.listIsProcess = false;
    this.solicitacoes = [];
    this.categories = [];
    this.categoryIsChanged = false;
    this.categoryIsCreated = false;
    this.categoryIsDeleted = false;
    this.limits = [];
    this.limitIsSeted = false;
    this.users = [];
    this.userIsChanged = false;
    this.passIsChanged = false;
    this.productIsChanged = false;
    this.productIsDeleted = false;
    this.productIsCreated = false;
    this.userIsCreated = false;
    this.userIsDeleted = false;
    this.statusIsChanged = false;
    this.productsInfo = [];
    this.sizes = [];
    this.colors = [];
    this.sizeIsChanged = false;
    this.sizeIsDeleted = false;
    this.sizeIsCreated = false;
    this.colorIsChanged = false;
    this.colorIsCreate = false;
    this.colorIsDeleted = false;
    this.cartIsSaved = false;
    this.cartItems = [];
    this.image = '';
    this.usersLimits = [];
    this.userLimits = [];
    this.categoryLimitIsCreated = false;
    this.categoryLimitIsChanged = false;
    this.categoryLimitIsDeleted = false;
  }

  async getImage(data) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    const arr = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index].productImage;
      arr.push(element);
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
        image: arr
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getimage`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.image = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async getProducts() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getproducts`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.products = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async getProductsWithFilters(filters) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .post(`${process.env.REACT_APP_API_URL}getproductswithfilters`,{
        filters: filters,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        if (data.error === false) {
          this.products = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async getProductsWithFilterName(name) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .post(`${process.env.REACT_APP_API_URL}getproductswithfiltersname`,{
        name: name,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        if (data.error === false) {
          this.products = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }
  formatHeader(items) {
    let header = {
      userName: localStorage.getItem("LOUZAN_userName"),
      date: "",
      hour: "",
      valor: 0,
    };
    for (let i = 0; i < items.length; i++) {
      header.valor =
      header.valor + items[i].productPrice;
    }
    return header;
  }
  formatListItems(cartItems) {
    let item = {
      productId: undefined,
      productName: undefined,
      productPrice: undefined,
      productImage: undefined,
      productType: undefined,
      productSizess: undefined,
      productColorss: undefined,
    };
    let formatedListItems = [];
    for (let i = 0; i < cartItems.length; i++) {
      item.productId = cartItems[i].productId;
      item.productName = cartItems[i].productName;
      item.productPrice = cartItems[i].productPrice;
      item.productImage = cartItems[i].productImage;
      item.productType = cartItems[i].productType;
      item.productSizess = cartItems[i].productSizess;
      item.productColorss = cartItems[i].productColorss;
      formatedListItems.push( this.cloneData(item) );
    }
    return formatedListItems;
  }

  formatListItems2(cartItems) {
    let item = {
      productId: undefined,
      productName: undefined,
      productPrice: undefined,
      productImage: undefined,
      productQuant: undefined,
      productType: undefined,
      productSizess: undefined,
      productColorss: undefined,
    };
    let formatedListItems = [];
    for (let i = 0; i < cartItems.length; i++) {
      item.productId = cartItems[i].productId;
      item.productName = cartItems[i].productName;
      item.productPrice = cartItems[i].productPrice;
      item.productImage = cartItems[i].productImage;
      item.productQuant = cartItems[i].productQuant;
      item.productType = cartItems[i].productType;
      item.productSizess = cartItems[i].productSizess;
      item.productColorss = cartItems[i].productColorss;
      formatedListItems.push( this.cloneData(item) );
    }
    return formatedListItems;
  }

  cloneData(data){
    var clone ={};
    for( var key in data ){
        if(data.hasOwnProperty(key)) //ensure not adding inherited props
            clone[key]=data[key];
    }
    return clone;
  }

  async sendListItems(header, items) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .post(`${process.env.REACT_APP_API_URL}newlist`, {
        header: header,
        items: items,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        this.listIsProcess = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async sendListItems2(listItems) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .post(`${process.env.REACT_APP_API_URL}savecart`, {
        listItems: listItems,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        this.cartIsSaved = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async clearCart() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .delete(`${process.env.REACT_APP_API_URL}clearcart`, {
        data: {
          token,
          key,
        },
      })
      .then(({ data }) => {
        this.categoryIsDeleted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async getCartItems() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getcart`, config)
      .then(({ data }) => {
        this.cartItems = data.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async getSolicitacoes() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getsolicitacoes`, config)
      .then(({ data }) => {
        this.solicitacoes = data.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async getCategories() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getcategories`, config)
      .then(({ data }) => {
        this.categories = data.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async changeCategory(id, newName) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changecategory/` + id, {
        newName,
        token,
        key,
      })
      .then(({ data }) => {
        this.categoryIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  }

  async createCategory(categoryName) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .post(`${process.env.REACT_APP_API_URL}createcategory`, {
        categoryName,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        this.categoryIsCreated = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          return;
        }
      });
  }

  async deleteCategory(id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .delete(`${process.env.REACT_APP_API_URL}deletecategory/` + id, {
        data: {
          token,
          key,
        },
      })
      .then(({ data }) => {
        this.categoryIsDeleted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async getLimits() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getlimits`, config)
      .then(({ data }) => {
        this.limits = data.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async setLimits(valor2) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changelimits`, {
        valor2,
        token,
        key,
      })
      .then(({ data }) => {
        this.limitIsSeted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  }

  async getUsers() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getusers`, config)
      .then(({ data }) => {
        this.users = data.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  }

  async changeUser(name, email, admin, id, quant, limitUnFormated) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changeuser/${id}`, {
        name,
        email,
        quant,
        limitUnFormated,
        admin: admin ? admin : 'Revendedor',
        token,
        key,
      })
      .then(({ data }) => {
        this.userIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  }

  async changePass(pass, id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changepass/${id}`, {
        pass,
        token,
        key,
      })
      .then(({ data }) => {
        this.passIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async addUser(userdata) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .post(`${process.env.REACT_APP_API_URL}createuser`, {
        userdata,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        this.userIsCreated = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          return;
        }
      });
  };

  async deleteUser(id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .delete(`${process.env.REACT_APP_API_URL}deleteuser/` + id, {
        data: {
          token,
          key,
        },
      })
      .then(({ data }) => {
        this.userIsDeleted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 404) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          return;
        }
      });
  };

  async getAllProducts() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getallproducts`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.allProducts = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async getProductsInfo(id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
        id: id
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getproductsinfo`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.productsInfo = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async changeProduct(product) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const dados = new FormData();
    dados.append("productName", product.productName);
    dados.append("productPrice", product.productPrice);
    dados.append("productQuant", product.productQuant);
    dados.append("productType", product.productType);
    dados.append("productImage", product.productImage);
    dados.append("productSize", product.productSize);
    dados.append("productColor", product.productColor);
    dados.append("token", token);
    dados.append("key", key);

    await axios
      .put(
        `${process.env.REACT_APP_API_URL}changeproduct/` + product.productId,
        dados,
        {
          headers: {
            "Content-Type": `multipart/form-data`,
          },
        }
      )
      .then(({ data }) => {
        this.productIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  }

  async deleteProduct(productId) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .delete(`${process.env.REACT_APP_API_URL}deleteproduct/` + productId, {
        data: {
          token,
          key,
        },
      })
      .then(({ data }) => {
        this.productIsDeleted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async createProduct(product) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    const dados = new FormData();
    dados.append("productName", product.productName);
    dados.append("productPrice", product.productPrice);
    dados.append("productQuant", product.productQuant);
    dados.append("productType", product.productType);
    dados.append("productImage", product.productImage);
    dados.append("productSize", product.productSize);
    dados.append("productColor", product.productColor);
    dados.append("token", token);
    dados.append("key", key);

    await axios
      .post(`${process.env.REACT_APP_API_URL}addproduct`, dados, {
        headers: {
          "content-type": `multipart/form-data`,
        },
      })
      .then(({ data }) => {
        this.productIsCreated = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async changeStatus(id, status) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changestatus/` + id, {
        status,
        token,
        key,
      })
      .then(({ data }) => {
        this.statusIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async getSizes() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getsizes`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.sizes = [];
          for (let i=0;i<data.data.length;i++){
            this.sizes.push(data.data[i].sizeValue)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async getSizess() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getsizes`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.sizes = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async changeSize(value, id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changesize/` + id, {
        value,
        token,
        key,
      })
      .then(({ data }) => {
        this.sizeIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async deleteSize(id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .delete(`${process.env.REACT_APP_API_URL}deletesize/` + id, {
        data: {
          token,
          key,
        },
      })
      .then(({ data }) => {
        this.sizeIsDeleted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async createSize(size) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .post(`${process.env.REACT_APP_API_URL}createsize`, {
        size,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        this.sizeIsCreated = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          return;
        }
      });
  };

  async getColors() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getcolors`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.colors = [];
          for (let i=0;i<data.data.length;i++){
            this.colors.push(data.data[i].colorName)
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async getColorss() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getcolors`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.colors = data.data
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async changeColor(value, id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changecolor/` + id, {
        value,
        token,
        key,
      })
      .then(({ data }) => {
        this.colorIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async deleteColor(id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .delete(`${process.env.REACT_APP_API_URL}deletecolor/` + id, {
        data: {
          token,
          key,
        },
      })
      .then(({ data }) => {
        this.colorIsDeleted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async createColor(color) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .post(`${process.env.REACT_APP_API_URL}createcolor`, {
        color,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        this.colorIsCreated = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 500) {
          return;
        }
      });
  };

  async getUsersLimits() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getuserslimits`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.usersLimits = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async getUserLimits() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: token,
        Key: key,
      },
    };

    await axios
      .get(`${process.env.REACT_APP_API_URL}getuserlimits`, config)
      .then(({ data }) => {
        if (data.error === false) {
          this.userLimits = data.data;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Sessão expirada
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
        }
      });
  };

  async addCategoryLimit(dados) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .post(`${process.env.REACT_APP_API_URL}createcategorylimit`, {
        dados,
        token: token,
        key: key,
      })
      .then(({ data }) => {
        this.categoryLimitIsCreated = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          return;
        }
      });
  }

  async changeCategoryLimit(dados) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .put(`${process.env.REACT_APP_API_URL}changecategorylimit/` + dados.id, {
        dados,
        token,
        key,
      })
      .then(({ data }) => {
        this.categoryLimitIsChanged = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };

  async deleteCategoryLimit(id) {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");
    await axios
      .delete(`${process.env.REACT_APP_API_URL}deletecategorylimit/` + id, {
        data: {
          token,
          key,
        },
      })
      .then(({ data }) => {
        this.categoryLimitIsDeleted = true;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          //Implementar mensagem de sessão expirada e manter na página de login
          localStorage.removeItem("LOUZAN_userId");
          localStorage.removeItem("LOUZAN_userName");
          localStorage.removeItem("LOUZAN_userEmail");
          localStorage.removeItem("LOUZAN_userKey");
          localStorage.removeItem("LOUZAN_token");
          localStorage.removeItem("LOUZAN_userFunction");
          localStorage.removeItem("LOUZAN_limitValue");
          localStorage.removeItem("LOUZAN_limitQuant");
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 404) {
          //Implementar mensagem de acesso negado
          window.location.replace(`${process.env.REACT_APP_SITE_URL}404`);
        }
        if (error.response.status === 500) {
          //Erro ao retornar dados do banco de dados
          return;
        }
      });
  };
}

export default exclusive;
