import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Header from "../../components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Category from "./categorias";
import "./config.css";
import Limits from "./limites";
import validations from "../../classes/validations";
import Sizes from "./sizes";
import Colors from "./colors";
import { ToastContainer } from "react-toastify";

export default function Config() {
  const validationClass = new validations();
  useEffect(() => { 
    checkAdminAcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkAdminAcess = async () => {
    await validationClass.checkAdmin();
  };
  return (
    <Container className="padding-b">
      <Row className="justify-content-md-left mb mt">
        <Col md="auto">
          <Header
            title="Configurações de Limites de Produtos"
            // subtitle="Gerenciamento de Categorias para Produtos"
          />
        </Col>
        <Limits />
      </Row>
      <Row className="justify-content-md-left">
        <Col md="auto">
          <Header
            title="Gerenciar Categorias de Produtos"
            // subtitle="Gerenciamento de Categorias para Produtos"
          />
        </Col>
        <Category />
      </Row>
      <Row className="justify-content-md-left ">
        <Col md="auto">
          <Header
            title="Gerenciar Tamanhos de Produtos"
            // subtitle="Gerenciamento de Categorias para Produtos"
          />
        </Col>
        <Sizes />
      </Row>
      <Row className="justify-content-md-left ">
        <Col md="auto">
          <Header
            title="Gerenciar Cores de Produtos"
            // subtitle="Gerenciamento de Categorias para Produtos"
          />
        </Col>
        <Colors />
      </Row>
      <ToastContainer autoClose={3000} position="top-right" />
    </Container>
  );
}
