import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import exclusive from "../../classes/exclusive";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import Header from "../../components/Header";
import NewCategoryLimit from "./newcategorylimit";
import ChangeCategoryLimit from "./changecategorylimit";
import DeleteCategoryLimit from "./deletecategorylimit";

export default function CategoryLimit({users}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const exclusiveClass = new exclusive();
  const [userLimits, setUserLimits] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [category, setCategory] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  useEffect(() => {
    getLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getLimits = async () => {
    await exclusiveClass.getUsersLimits();
    setUserLimits(exclusiveClass.usersLimits);
  };
  const columns = [
    {
      field: "icone",
      headerName: "",
      renderCell: ({ row: { id } }) => {
        return (
          <>
            <Button
              variant="info"
              className="pointer"
              onClick={() => handleEdit(userLimits, id)}
            >
              Modificar
            </Button>
          </>
        );
      },
      width: 140,
    },
    { field: "userName", headerName: "Nome do Usuário", width: 350 },
    { field: "category", headerName: "Categoria", width: 150 },
    { field: "limitt", headerName: "Limite", width: 90 },
    {
        field: "icone2",
        headerName: "",
        renderCell: ({ row: { id } }) => {
          return (
            <>
              <Button
                variant="danger"
                className="pointer"
                onClick={() => handleDelete(userLimits, id)}
              >
                Deletar
              </Button>
            </>
          );
        },
        width: 140,
      },
  ];
  const handleEdit = (userLimits, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
      userLimits.find((row) => row.id === id)
    );
    setCategory(selectedRowsData[0]);
    setShow2(true);
  };
  const handleDelete = (userLimits, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
      userLimits.find((row) => row.id === id)
    );
    setCategory(selectedRowsData[0]);
    setShow3(true);
  };
  const handleCreate = () => {
    setShow(true);
  };
  return (
    <Container className="mt">
      <Box m="20px">
        <Box
          display={smScreen ? "flex" : "block"}
          flexDirection={smScreen ? "row" : "column"}
          justifyContent={smScreen ? "space-between" : "start"}
          alignItems={smScreen ? "center" : "start"}
          m="10px 0"
        >
          <Header
            title="Limites por Categoria"
            subtitle="Configuração de limite por categoria para cada usuário."
          />
        </Box>
      </Box>
      <Grid
        className="sombra"
        m="20px 0 0 0"
        p="10px"
        xs={12}
        backgroundColor={colors.primary[100]}
        // sx={{
        //   width: 590,
        // }}
      >
        <Button variant="primary" onClick={handleCreate}>
          Novo Limite
        </Button>
        <Box
          m="8px 0 0 0"
          width="100%"
          //   maxWidth={580}
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
          }}
        >
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={userLimits}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.id}
          />
        </Box>
      </Grid>
      {show2 && (
        <ChangeCategoryLimit
          setShow2={setShow2}
          show2={show2}
          users={users}
          getLimits={getLimits}
          categoria={category}
        />
      )}
      {show && (
        <NewCategoryLimit
          setShow={setShow}
          show={show}
          getLimits={getLimits}
          users={users}
        />
      )}
      {show3 &&(<DeleteCategoryLimit show3={show3} setShow3={setShow3} categoria={category} getLimits={getLimits} />)}
      <ToastContainer autoClose={3000} position="top-right" />
    </Container>
  );
}
