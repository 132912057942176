import React, { useState, useEffect, useContext } from "react";
import ProductCard from "./productCard";
import "./products.css";
import Loading from "../../components/loading/loading";
import exclusive from "../../classes/exclusive";
import AppContext from "../../context/AppContext";
import Filters from "../../components/filters/filters";
import Search from './search'

function Products({ valor, valor2, valor3, userLimits }) {
  const exclusiveClass = new exclusive();
  const { products, setProducts, productsFilters, name } = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProducts = async () => {
    if (productsFilters.length > 0) {
      await exclusiveClass.getProductsWithFilters(productsFilters);
      setProducts(exclusiveClass.products);
    } else {
      await exclusiveClass.getProducts();
      setProducts(exclusiveClass.products);
    }
    setLoading(false);
  };

  const getProductsWithName = async () => {
    if (name){
      await exclusiveClass.getProductsWithFilterName(name);
      setProducts(exclusiveClass.products);
      setLoading(false);
      return
    }
  };

  return (
    (loading && <Loading />) || (
      <div>
        <Filters getProducts={getProducts} />
        <section className="container-producs2 products2">
          <Search getProductsWithName={getProductsWithName} />
        </section>
        <section className="container-producs products">
          {products.map((product) => (
            <ProductCard
              key={product.productId}
              data={product}
              valor={valor}
              valor2={valor2}
              valor3={valor3}
              userLimits={userLimits}
            />
          ))}
        </section>
      </div>
    )
  );
}

export default Products;
