import React from "react";
import Container from "react-bootstrap/Container";
import Slider from "./slider";
import "./home.css";
import Cards from "./cards";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Typography from "@mui/material/Typography";
import Cards2 from "./cards2";

const Home = () => {
  return (
    <Container className="padding-b">
      <Slider />
      <div className="separador">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Typography className="text1">
              Louzan Joias: Sua Fonte de Brilho
            </Typography>
          </Col>
        </Row>
        <Row className="justify-content-md-center mb">
          <Col md="auto">
            <Typography className="text2">
              Bem-vindo à nossa loja, onde cada peça é escolhida com cuidado
              para oferecer o melhor em elegância e qualidade. Nossa coleção de
              joias é perfeita para quem busca estilo, sofisticação e um toque
              especial para qualquer ocasião. Com opções exclusivas e preços
              acessíveis, tornamos o luxo acessível a todos. Venha descobrir o
              brilho que faltava na sua vida!
            </Typography>
          </Col>
        </Row>
      </div>
      <Cards />
      <div className="separador">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Typography className="text1">
              O que é consignado em prata?
            </Typography>
          </Col>
        </Row>
        <Row className="justify-content-md-center mb">
          <Col md="auto">
            <Typography className="text2">
              Reunimos cuidadosamente um kit com mais de 70 opções incríveis e
              você tem um generoso prazo de 40 dias para acertar as peças
              vendidas, com uma comissão de 35% garantida em cada venda.
            </Typography>
            <Typography className="text2">
              Não perca essa oportunidade única de ganhar mais DINHEIRO.
              Junte-se a nós hoje e comece a aproveitar os benefícios do nosso
              programa de empréstimo consignado!
            </Typography>
          </Col>
        </Row>
      </div>
      <Cards2 />
      <div className="separador">
        <Row className="justify-content-md-center">
          <Col md="auto">
            <Typography className="text1">Dúvidas frequentes</Typography>
          </Col>
        </Row>
        <Row className="justify-content-md-center mb">
          <Col md="auto">
            <Typography className="text2">As joias tem garantia?</Typography>
            <Typography className="text2">
              Certificamos que nossas peças são feitas em prata 925 e possuem
              garantia vitalícia quanto à legitimidade do metal. Esclarecemos
              que a prata pode passar pela oxidação que leva a joia a escurecer,
              e que nesse caso é feito a limpeza usando Monzi limpa pratas e uma
              flanela. Dessa forma, a oxidação não é assegurada pela garantia
              vitalícia, assim como mau uso e perda de pedras.
            </Typography>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default Home;
