import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function CreateCategory({ show2, setShow2, getCategories, categories }) {
  const [categoryName, setCategoryName] = useState("");
  const exclusiveClass = new exclusive();
  const handleClose = () => setShow2(false);
  const handleCreate = async () => {
    if (!categoryName || categoryName.length < 3) {
      return toast.warn("Preencher o nome da categoria corretamente.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }

    if(categories.find(o => o.typeName === categoryName)){
      return toast.warn("Já existe uma categoria com este nome.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    
    await exclusiveClass.createCategory(categoryName);
    if (exclusiveClass.categoryIsCreated) {
      setShow2(false);
      getCategories();
      return toast.success("Nova Categoria adicionada com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setShow2(false);
    return toast.error(
      "Erro ao criar Categoria. Tente novamente mais tarde ou entre em contato com o administrador",
      {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };
  return (
    <>
      <Modal size="sm" centered show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nome da Categoria</Form.Label>
              <Form.Control
                required
                value={categoryName}
                maxLength={25}
                onChange={(e) => setCategoryName(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCreate}>
            Criar Categoria
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
