import axios from "axios";
// import { toast } from "react-toastify";

class validations {
  constructor() {
    this.userIsValid = false;
    this.loading = true;
  }

  async checkUser() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}validation`, {
          Authorization: token,
          key,
        })
        .then(({ data }) => {
          localStorage.setItem("LOUZAN_userId", data.userId);
          localStorage.setItem("LOUZAN_userName", data.userName);
          localStorage.setItem("LOUZAN_userEmail", data.userEmail);
          localStorage.setItem("LOUZAN_userFunction", data.userFunction);
          localStorage.setItem("LOUZAN_limitValue", data.limitValue);
          localStorage.setItem("LOUZAN_limitQuant", data.limitQuant);
          window.location.replace(`${process.env.REACT_APP_SITE_URL}exclusive`);
        });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("LOUZAN_userId");
        localStorage.removeItem("LOUZAN_userName");
        localStorage.removeItem("LOUZAN_userEmail");
        localStorage.removeItem("LOUZAN_userKey");
        localStorage.removeItem("LOUZAN_token");
        localStorage.removeItem("LOUZAN_userFunction");
        localStorage.removeItem("LOUZAN_limitValue");
        localStorage.removeItem("LOUZAN_limitQuant");
        return;
      }
    }
  }

  async tryLogin(email, pass) {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}login`, {
          email,
          pass,
        })
        .then(({ data }) => {
          if (data.error === false) {
            localStorage.setItem("LOUZAN_userId", data.userId);
            localStorage.setItem("LOUZAN_userName", data.userName);
            localStorage.setItem("LOUZAN_userEmail", data.userEmail);
            localStorage.setItem("LOUZAN_userKey", data.userKey);
            localStorage.setItem("LOUZAN_token", data.token);
            localStorage.setItem("LOUZAN_userFunction", data.userFunction);
            localStorage.setItem("LOUZAN_limitValue", data.limitValue);
            localStorage.setItem("LOUZAN_limitQuant", data.limitQuant);
            window.location.replace(
              `${process.env.REACT_APP_SITE_URL}exclusive`
            );
          }
        });
    } catch (error) {
      if (error.response.status === 401) {
        return error.response.data.message;
      }
      if (error.response.status === 500) {
        return error.response.data.message;
      }
    }
  }

  async checkLogin() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}validation`, {
          Authorization: token,
          key,
        })
        .then(({ data }) => {
          localStorage.setItem("LOUZAN_userId", data.userId);
          localStorage.setItem("LOUZAN_userName", data.userName);
          localStorage.setItem("LOUZAN_userEmail", data.userEmail);
          localStorage.setItem("LOUZAN_userFunction", data.userFunction);
          localStorage.setItem("LOUZAN_limitValue", data.limitValue);
          localStorage.setItem("LOUZAN_limitQuant", data.limitQuant);
          this.userIsValid = true;
        });
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("LOUZAN_userId");
        localStorage.removeItem("LOUZAN_userName");
        localStorage.removeItem("LOUZAN_userEmail");
        localStorage.removeItem("LOUZAN_userKey");
        localStorage.removeItem("LOUZAN_token");
        localStorage.removeItem("LOUZAN_userFunction");
        localStorage.removeItem("LOUZAN_limitValue");
        localStorage.removeItem("LOUZAN_limitQuant");
        this.userIsValid = false;
      }
    }
  }

  async checkAdmin() {
    const token = localStorage.getItem("LOUZAN_token");
    const key = localStorage.getItem("LOUZAN_userKey");

    await axios
      .post(`${process.env.REACT_APP_API_URL}checkadmin`, {
        Authorization: token,
        key,
      })
      .then(({ data }) => {
        this.loading = false;
      })
      .catch((error) => {
        if (error.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}negado`);
        }
        if (error.response.status === 401) {
          window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
        }
        window.location.replace(`${process.env.REACT_APP_SITE_URL}error`);
      });
  }
}

export default validations;
