import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalImage({ show, setShow, productName, productImage }) {
  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{productName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={productImage}
            alt="product"
            className="card__image2"
            //onClick={openImage}
          />
          

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalImage;
