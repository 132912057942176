import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function CreateColor({ show2, setShow2, getColors }) {
  const [colorName, setColorName] = useState("");
  const exclusiveClass = new exclusive();
  const handleClose = () => setShow2(false);
  const handleCreate = async () => {
    if (!colorName) {
      return toast.warn("Preencher o nome da Cor corretamente.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }

    await exclusiveClass.createColor(colorName);
    if (exclusiveClass.colorIsCreated) {
      setShow2(false);
      getColors();
      return toast.success("Cor criada com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setShow2(false);
    return toast.error(
      "Erro ao criar Cor. Tente novamente mais tarde ou entre em contato com o administrador.",
      {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };
  return (
    <>
      <Modal size="sm" centered show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Cor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Tamanho</Form.Label>
              <Form.Control
                required
                value={colorName}
                maxLength={25}
                onChange={(e) => setColorName(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCreate}>
            Criar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
