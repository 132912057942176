import React, { useContext } from "react";
import "./cartItem.css";
import { BsCartXFill } from "react-icons/bs";
import propTypes from "prop-types";
import AppContext from "../../context/AppContext";

function CartItem({ data, index }) {
  const { productId, productName, productPrice, productImage, productQuant, productSizess, productColorss } =
    data;
  const { cartItems, setCartItems } = useContext(AppContext);
  const handleRemoveItem = () => {
    const updatedItems = cartItems.filter(
      (item, idx) => idx !== index //item.productId != productId
    );
    setCartItems(updatedItems);
    console.log(productId,productQuant)
  };
  return (
    <section className="cart-item">
      <img
        src={productImage}
        alt="imagem do produto"
        className="cart-item-image"
      />

      <div className="cart-item-content">
        <h3 className="cart-item-title">{productName}</h3>
        <h3 className="cart-item-price">{productPrice.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</h3>
        {productSizess !== 'null' && productSizess !== 'undefined' && productSizess !== undefined && <p className="cart-item-size">Tamanho: {productSizess}</p>}
        {productColorss !== 'null' && productColorss !== 'undefined' && productColorss !== undefined && <p className="cart-item-size">Cor: {productColorss}</p>}

        <button
          type="button"
          className="button__remove-item"
          onClick={handleRemoveItem}
        >
          <BsCartXFill />
        </button>
      </div>
    </section>
  );
}

export default CartItem;

CartItem.propTypes = {
  data: propTypes.object,
}.isRequired;
