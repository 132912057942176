import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";

export default function Cards2() {
  const handleInsta = () => {
    const url = "https://instagram.com/louzanjoias";
    window.open(url, "_blank").focus();
  };
  const handleExclusive = () => {
    window.location.replace(`${process.env.REACT_APP_SITE_URL}exclusive`);
  };
  return (
    <Box sx={{ flexGrow: 1 }} className="mb">
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardActionArea onClick={handleInsta}>
              <div className="hidden">
                <CardMedia
                  component="img"
                  height="550"
                  image="./images/banner03-prata.png"
                  alt="joias"
                  className="zoom-in"
                />
              </div>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                  align="center"
                >
                  Siga-nos no Instagram!
                </Typography>
                <Typography
                  className="legenda"
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Fique por dentro de todas as tendências e novidades que estão
                  por vir.
                </Typography>
                <Typography className="legenda" variant="body2" align="center">
                  <img src="./insta-svg.png" alt="insta" />
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
          <Card>
            <CardActionArea onClick={handleExclusive}>
              <div className="hidden">
                <CardMedia
                  component="img"
                  height="550"
                  image="./images/banner04-prata.jpg"
                  alt="joias"
                  className="zoom-in"
                />
              </div>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  align="center"
                  component="div"
                >
                  Área Exclusiva Para Revendedores!
                </Typography>
                <Typography
                  className="legenda"
                  variant="body2"
                  color="text.secondary"
                  align="center"
                >
                  Já é nossa revendedora? Acesse a área exclusiva e faça sua
                  encomenda agora mesmo.
                </Typography>
                <Typography
                  className="legenda logo-space"
                  variant="body2"
                  align="center"
                >
                  <img src="./logo.png" alt="insta" />
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
