import React, { useContext, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import AppContext from "../../context/AppContext";
import Button from "react-bootstrap/Button";
import "./filters.css";
import exclusive from "../../classes/exclusive";

function Filters({ getProducts }) {
  const exclusiveClass = new exclusive();
  const [categories, setCategories] = useState([]);
  const { productsFilters, setProductsFilters } = useContext(AppContext);
  useEffect(() => {
    getCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCategories = async () => {
    await exclusiveClass.getCategories();
    setCategories(exclusiveClass.categories);
  }
  const changeFilter = (id) => {
    if (!productsFilters.includes(`'${id}'`)) {
      setProductsFilters([...productsFilters, `'${id}'`]);
    } else {
      let filtro = productsFilters;
      const index = filtro.indexOf(`'${id}'`);
      if (index !== -1) {
        filtro.splice(index, 1);
      }
      setProductsFilters(filtro);
    }
  };
  const applyFilters = () => {
    getProducts();
  };
  return (
    <Accordion className="margin-topp">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Filtros</Accordion.Header>
        <Accordion.Body>
          {categories.map((filter) => (
            <Form.Check
              inline
              label={filter.typeName}
              id={filter.typeName}
              type="checkbox"
              onChange={(e) => changeFilter(e.target.id)}
            />
          ))}
          <div>
            <Button className="btn-env" variant="info" onClick={applyFilters}>
              Aplicar Filtros
            </Button>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Filters;
