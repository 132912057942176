import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Typography from "@mui/material/Typography";
import './aboutus.css';

export default function AboutUs() {
  return (
    <Container className="padding-b">
      <Row className="justify-content-md-center mb mt">
        <img src="quemsomos.png" alt="Quem somos" />
        <Typography gutterBottom variant="h5" component="div" className="titulo">
          Sobre a Louzan Joias
        </Typography>
        <Typography className="legenda" variant="body2" color="text.secondary">
          Empreendedores dedicados ao ramo de joalheria, com especialização em
          Prata 925, oferecendo garantia vitalícia de qualidade desde março de
          2022, no modelo de consignação. Localizados em Travessão de Barra, São
          Francisco de Itabapoana - RJ, trazemos sofisticação e elegância
          através de nossas peças exclusivas. excelência.
        </Typography>
        <Typography className="legenda" variant="body2" color="text.secondary">
          Ao trabalharmos com um sistema de revenda, proporcionamos às nossas
          revendedoras a oportunidade de obter uma renda extra significativa,
          além de muita satisfação e reconhecimento ao oferecerem joias de
          altíssima qualidade.
        </Typography>
        <Typography className="legenda" variant="body2" color="text.secondary">
          Nossa missão é valorizar a beleza e o brilho de cada cliente, através
          de um atendimento personalizado e um compromisso inabalável com a
          excelência.
        </Typography>
      </Row>
    </Container>
  );
}
