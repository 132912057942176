import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";
import Select from "react-select";

export default function NewCategoryLimit({ show, setShow, getLimits, users }) {
  const exclusiveClass = new exclusive();
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getCategories = async () => {
    await exclusiveClass.getCategories();
    setCategories(changeData(exclusiveClass.categories));
  };
  const handleClose = () => setShow(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [userName, setUserName] = useState();
  const [userId, setUserId] = useState();
  const [quant, setQuant] = useState();
  // eslint-disable-next-line no-unused-vars
  const [isClearable, setIsClearable] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isSearchable, setIsSearchable] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isRtl, setIsRtl] = useState(false);
  const changeData = (data) => {
    for (var linha of data) {
      Object.defineProperty(linha, "label", {
        value: linha.userName ? linha.userName : linha.typeName,
      });
    }
    return data;
  };
  const usersName = changeData(users);
  const setUserName2 = (e) => {
    if (!e) {
      setUserName("");
    } else {
      setUserName(e.userName);
      setUserId(e.userId);
    }
  };
  const changeCategory = (e) => {
    if (!e) {
      setCategory("");
    } else {
      setCategory(e.typeName);
    }
  };
  const handleChange = async () => {
    if (!userName) {
      return toast.error("Selecione um Usuário.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (!category) {
      return toast.error("Selecione uma Categoria.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (quant <= 0 || !quant) {
      return toast.error("Insira um limite valido.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    const dados = {
        userId: userId,
        userName: userName,
        category: category,
        limit: quant
    }
    await exclusiveClass.addCategoryLimit(dados);
    if (exclusiveClass.categoryLimitIsCreated) {
      setShow(false);
      getLimits()
      return toast.success("Dados salvos com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      return toast.error(
        "Ocorreu um erro. Verifique se o limte já foi cadastrado ou tente novamente mais tarde.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
  };
  return (
    <>
      <Modal size="md" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Cadastrar Novo Limite Para Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridMarca">
              <Form.Label>Usuário</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                defaultValue=""
                name="typeName"
                options={usersName}
                onChange={setUserName2}
                placeholder="Selecione"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridMarca">
              <Form.Label>Categoria</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                defaultValue=""
                name="userName"
                options={categories}
                onChange={changeCategory}
                placeholder="Selecione"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Label>Limite de Peças</Form.Label>
            <Col xs={6} md={4}>
              <Form.Group as={Col}>
                <Form.Control
                  required
                  value={quant}
                  onChange={(e) => setQuant(e.target.value)}
                  size="md"
                  type="number"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChange}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
