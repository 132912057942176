import React, { useContext } from "react";
import "./cart.css";
import CartItem from "../cartItem/cartItem";
import AppContext from "../../context/AppContext";
import Button from "react-bootstrap/Button";
import { Slide, toast } from "react-toastify";
import exclusive from "../../classes/exclusive";

function Cart() {
  const exclusiveClass = new exclusive();
  const { cartItems, setCartItems, isCartVisible, setIsCartVisible } =
    useContext(AppContext);
  const totalPrice = cartItems.reduce(
    (acc, item) => item.productPrice + acc,
    0
  );
  const handleSave = async () => {
    if (cartItems.length < 1) {
      await exclusiveClass.clearCart();
      return toast.success("Carrinho Salvo.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    const cartItemsFormated = exclusiveClass.formatListItems2(cartItems);
    await exclusiveClass.clearCart();
    await exclusiveClass.sendListItems2(cartItemsFormated);
    if (exclusiveClass.cartIsSaved) {
      return toast.success("Carrinho Salvo.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      return toast.danger(
        "Erro ao Salvar dados do carrinho. Entre em contato com o administrador do sistema.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
  };

  const handleSand = async () => {
    if (cartItems.length < 1) {
      return toast.warn("Nenhum item adicionado ao kit.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    const cartHeader = exclusiveClass.formatHeader(cartItems);
    const itemCart = exclusiveClass.formatListItems(cartItems);
    await exclusiveClass.sendListItems(cartHeader, itemCart);
    if (exclusiveClass.listIsProcess) {
      setCartItems([]);
      setIsCartVisible(false);
      return toast.success("kit solicitado com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      setCartItems([]);
      setIsCartVisible(false);
      return toast.danger(
        "Erro ao enviar solicitação. Tente novamente mais tarde ou entre em contato com o administrador.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
  };
  return (
    <section className={`cart ${isCartVisible ? "cart--active" : ""}`}>
      <div className="fechar">
        {/* <FaWindowClose onClick={() => setIsCartVisible(!isCartVisible)} /> */}
        <button
          className="btn-fechar"
          onClick={() => setIsCartVisible(!isCartVisible)}
        >
          Fechar
        </button>
      </div>
      <div className="cart-items">
        {cartItems.map((cartItem, index) => (
          <CartItem key={index} data={cartItem} index={index} />
        ))}
      </div>
      <div className="cart-resume">
        {totalPrice.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })}
      </div>
      <div>
        <Button className="margin-bt" variant="success" onClick={handleSave}>
          Salvar Dados
        </Button>
        <Button variant="info" onClick={handleSand}>
          Enviar Solicitação
        </Button>
      </div>
    </section>
  );
}

export default Cart;
