import React, { useState } from "react";
import AppContext from "./AppContext";
import propTypes from "prop-types";

function Provider({ children }) {
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isCartVisible, setIsCartVisible] = useState(false);
  const [productsFilters, setProductsFilters] = useState([]);
  const [name, setName] = useState('');
  const value = {
    products,
    setProducts,
    cartItems,
    setCartItems,
    isCartVisible,
    setIsCartVisible,
    productsFilters,
    setProductsFilters,
    name, 
    setName
  };
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default Provider;

Provider.propTypes = {
  children: propTypes.any,
}.isRequired;
