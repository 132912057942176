import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import exclusive from "../../classes/exclusive";
import "./config.css";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import EditColor from "./editcolor";
import CreateColor from "./addcolor";

export default function Colors() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const exclusiveClass = new exclusive();
  const [colorss, setColorss] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [color, setColor] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  useEffect(() => {
    getColors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getColors = async () => {
    await exclusiveClass.getColorss();
    setColorss(exclusiveClass.colors);
  };
  const columns = [
    {
      field: "icone",
      headerName: "",
      renderCell: ({ row: { colorId } }) => {
        return (
          <>
            <Button
              variant="info"
              className="pointer"
              onClick={() => handleEdit(colorss, colorId)}
            >
              Modificar
            </Button>
          </>
        );
      },
      width: 140,
    },
    { field: "colorName", headerName: "Cor", width: 200 },
  ];
  const handleEdit = (colorss, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
        colorss.find((row) => row.colorId === id)
    );
    setColor(selectedRowsData[0]);
    setShow(true);
  };
  const handleCreate = () => {
    setShow2(true);
  };
  return (
    <Container className="padding-b">
      <Row className="justify-content-md-left">
        <Col md="auto">
          <Button variant="primary" onClick={handleCreate}>
            Criar Cor
          </Button>
        </Col>
      </Row>
      <Grid
        className="sombra"
        m="20px 0 0 0"
        p="10px"
        xs={12}
        backgroundColor={colors.primary[100]}
        sx={{
          width: 590,
        }}
      >
        <Box
          m="8px 0 0 0"
          width="100%"
          maxWidth={580}
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
          }}
        >
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={colorss}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.colorId}
          />
        </Box>
      </Grid>
      {show && (
        <EditColor
          setShow={setShow}
          show={show}
          getColors={getColors}
          colorr={color}
        />
      )}
      {show2 && (
        <CreateColor
          setShow2={setShow2}
          show2={show2}
          getColors={getColors}
        />
      )}
      <ToastContainer autoClose={3000} position="top-right" />
    </Container>
  );
}
