import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import exclusive from "../../classes/exclusive";
import { Slide, toast, Bounce } from "react-toastify";

export default function ConfirmDecision({ show2, setShow2, setShow, size, getSizes }) {
  const exclusiveClass = new exclusive();
  const handleClose = () => setShow2(false);
  const handleDelete = async () => {
    toast.info("Excluindo Tamanho!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    await exclusiveClass.deleteSize(size.sizeId);
    if (exclusiveClass.sizeIsDeleted) {
      setShow2(false);
      setShow(false);
      getSizes();
      return toast.success("Tamanho excluido com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setShow2(false);
    setShow(false);
    return toast.error(
      "Erro ao excluir Tamanho. Tente novamente mais tarde ou entre em contato com o administrador.",
      {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };
  return (
    <div className="asdasdad">
      <Modal size="md" show={show2} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>Confirmar Exclusão</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <p>Deseja realmente EXCLUIR o Tamanho "{size.sizeValue}"?</p>
          <p>Uma vez confirmada, esta ação não poderá ser desfeita.</p>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="primary" onClick={handleDelete}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
