import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast, Bounce } from "react-toastify";

export default function CreateProduct({
  show2,
  setShow2,
  getProducts,
  categories,
  sizess,
  colorss
}) {
  const handleClose = () => setShow2(false);
  const [productName, setProductName] = useState();
  const [productPrice, setProductPrice] = useState();
  const [productPriceUnFormated, setProductPriceUnFormated] = useState();
  const [productQuant, setProductQuant] = useState();
  const [productType, setProductType] = useState(categories[0]?categories[0].typeName:undefined);
  const [productImage, setProductImage] = useState();
  const [newImage, setNewImage] = useState();
  const exclusiveClass = new exclusive();
  const handleCreate = async () => {
    if (!newImage) {
      return toast.error("Selecione uma imagem.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (!productName || productName.length < 3) {
      return toast.error("Nome do Produto incorreto.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (!productPrice) {
      return toast.error("Insira um preço valido para o produto.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }

    const newProduct = {
      productName,
      productPrice: productPriceUnFormated,
      productQuant: productQuant ? productQuant : 0,
      productType,
      productImage: newImage ? newImage : "",
      productSize: sizes,
      productColor: colors
    };
    toast.info("Criando Produto!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    });
    await exclusiveClass.createProduct(newProduct);
    if (exclusiveClass.productIsCreated) {
      setShow2(false);
      getProducts();
      return toast.success("Produto criado com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setShow2(false);
    return toast.error("Erro ao criar Produto. Tente novamente mais tarde.", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
  };
  const mascaraMoeda = (event) => {
    const onlyDigits = event
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    setProductPrice(maskCurrency(digitsFloat));
    let valor2 = maskCurrency(digitsFloat);
    valor2 = valor2.replace("R$", "");
    valor2 = valor2.replace(".", "");
    valor2 = valor2.replace(",", ".");
    valor2 = valor2.trim();
    setProductPriceUnFormated(valor2);
  };

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }
  const setImagemFunction = (data) => {
    // if (data?.size > 524288) {
    //   return toast.warn("Arquivo muito grande. Selecione um arquivo menor");
    // }
    if (data === undefined) {
      setNewImage(null);
      setProductImage(null);
    } else {
      setNewImage(data);
      var lerArquivo = new FileReader();

      lerArquivo.onload = function (imagem) {
        const imagembase64_aux = imagem.target.result;
        setProductImage(imagembase64_aux);
      };

      lerArquivo.readAsDataURL(data);
    }
  };
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);
  const addSize = (size) => {
    let newArray = sizes;
    const index = newArray.indexOf(size);
    if (index > -1) {
      newArray.splice(index, 1);
      setSizes(newArray);
    } else {
      newArray.push(size);
      newArray.sort((a, b) => a - b);
      setSizes(newArray);
    }
  };
  const addColor = (color) => {
    let newArray = colors;
    const index = newArray.indexOf(color);
    if (index > -1) {
      newArray.splice(index, 1);
      setColors(newArray);
    } else {
      newArray.push(color);
      newArray.sort((a, b) => a - b);
      setColors(newArray);
    }
  };
  return (
    <>
      <Modal size="lg" centered show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Novo Produto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col xs={6} md={6}>
              {productImage && (
                <img
                  src={productImage}
                  alt="product"
                  className="product-image"
                />
              )}
              <Form.Label>Carregar Imagem</Form.Label>
              <Form.Control
                id="input"
                className="input"
                accept={[".png", ".jpg", "jpeg"]}
                onChange={(e) => setImagemFunction(e.target.files[0])}
                rest
                la
                type="file"
                size="sm"
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nome do Produto</Form.Label>
              <Form.Control
                required
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={3}>
              <Form.Group as={Col}>
                <Form.Label>Preço do Produto</Form.Label>
                <Form.Control
                  required
                  value={productPrice}
                  onChange={(e) => mascaraMoeda(e.target.value)}
                  size="md"
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={2}>
              <Form.Group as={Col}>
                <Form.Label>Quantidade</Form.Label>
                <Form.Control
                  required
                  value={productQuant}
                  onChange={(e) => setProductQuant(e.target.value)}
                  size="md"
                  type="text"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={12}>
              <Form.Label>Tamanhos:</Form.Label>
              <Form>
                {sizess.map((size) => (
                  <Form.Check // prettier-ignore
                    inline
                    type="checkbox"
                    id={size}
                    label={size}
                    value={size}
                    onChange={(e) => addSize(e.target.value)}
                  />
                ))}
              </Form>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={12}>
              <Form.Label>Cores:</Form.Label>
              <Form>
                {colorss.map((color) => (
                  <Form.Check // prettier-ignore
                    inline
                    type="checkbox"
                    id={color}
                    label={color}
                    value={color}
                    onChange={(e) => addColor(e.target.value)}
                  />
                ))}
              </Form>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col xs={6} md={4}>
              <Form.Group as={Col}>
                <Form.Label>Categoria do Produto</Form.Label>
                <Form.Select
                  size="md"
                  aria-label="Default select example"
                  onChange={(e) => setProductType(e.target.value)}
                >
                  {categories.map((e) => {
                    return (
                      <option
                        selected={productType === e.typeName ? "true" : ""}
                        key={e.typeId}
                        value={e.typeName}
                      >
                        {e.typeName}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCreate}>
            Criar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
