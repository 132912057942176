import React, { useContext, useState } from "react";
import propTypes from "prop-types";
import "./productCard.css";
import { FaCartPlus } from "react-icons/fa6";
import AppContext from "../../context/AppContext";
import { Slide, toast } from "react-toastify";
import ModalImage from "../../components/modalImage/modalImage";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function ProductCard({ data, valor, valor2, valor3, userLimits }) {
  const [show, setShow] = useState(false);
  const limitQuant = Number(valor);
  const { productName, productPrice, productImage, productSize, productColor } =
    data;
  const tamanhos = productSize ? productSize?.split(",") : undefined;
  const cores = productColor ? productColor?.split(",") : undefined;
  const { cartItems, setCartItems } = useContext(AppContext);
  const [tamanho, setTamanho] = useState(tamanhos ? "Selecione" : undefined);
  const [cor, setCor] = useState(cores ? "Selecione" : undefined);
  const handleAddCart = () => {
    if (tamanho === "Selecione") {
      return toast.warn(`Selecione um tamanho.`, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (cor === "Selecione") {
      return toast.warn(`Selecione uma Cor.`, {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    data["productSizess"] = tamanho;
    data["productColorss"] = cor;
    const updatedItems = cartItems.filter(
      (item) => item.productId === data.productId
    );
    if (updatedItems.length >= valor2) {
      return toast.warn(
        `Item não adicionado. Limite de ${valor2} itens iguais por solicitação atingido.`,
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
    if (cartItems.length === limitQuant) {
      return toast.warn(
        `Item não adicionado. Limite de ${valor} itens por solicitação atingido.`,
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
    const totalPrice = cartItems.reduce(
      (acc, item) => item.productPrice + acc,
      0
    );
    if (totalPrice + data.productPrice > valor3) {
      const n = Number(valor3);
      return toast.warn(
        `Item não adicionado. Limite de ${n.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        })} por solicitação será ultrapassado.`,
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
    const n = cartItems.filter(
      ({ productType }) => productType === data.productType
    ).length;
    const categoryLimit = userLimits.filter(
      ({ category }) => category === data.productType
    );
    const limitt = categoryLimit.length === 0 ? 999 : categoryLimit[0].limitt;
    if (n !== 0 && n >= limitt) {
      return toast.warn(
        `Item não adicionado. Você só pode adicionar ${limitt} itens da categoria ${data.productType}.`,
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
    setCartItems([...cartItems, data]);
    return toast.success(`Item adicionado ao Carrinho.`, {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
  };
  const openImage = () => {
    setShow(true);
  };
  const handleTamanho = (e) => {
    setTamanho(e);
  };
  const handleCor = (e) => {
    setCor(e);
  };
  return (
    <section className="product-card ">
      <img
        src={productImage}
        alt="product"
        className="card__image"
        onClick={openImage}
      />

      <div className="card__infos">
        <h2 className="card__price">
          {productPrice.toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          })}
        </h2>
        <h2 className="card__title">{productName}</h2>
        {/* <Col xs={6} md={5}> */}
        <Row>
          {productSize && (
            <Form.Group as={Col}>
              <Form.Label>Tamanho</Form.Label>
              <Form.Select
                size="sm"
                aria-label="Default select example"
                onChange={(e) => handleTamanho(e.target.value)}
                id="tamanhos"
              >
                <option value="">Selecione</option>
                {tamanhos.map((e) => {
                  return (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
          {productColor && (
            <Form.Group as={Col}>
              <Form.Label>Cor</Form.Label>
              <Form.Select
                size="sm"
                aria-label="Default select example"
                id="cores"
                onChange={(e) => handleCor(e.target.value)}
              >
                <option value="">Selecione</option>
                {cores.map((e) => {
                  return (
                    <option key={e} value={e}>
                      {e}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
        </Row>
        {/* </Col> */}
      </div>

      <button
        type="button"
        className="button__add-cart"
        onClick={handleAddCart}
      >
        <FaCartPlus />
      </button>
      {show && (
        <ModalImage
          show={show}
          setShow={setShow}
          productName={productName}
          productImage={productImage}
        />
      )}
    </section>
  );
}

export default ProductCard;

ProductCard.propTypes = {
  data: propTypes.shape({}),
}.isRequired;
