import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

export default function Cards() {
    return (
        <Box sx={{ flexGrow: 1 }} className='mb'>
            <Grid container spacing={2}>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card >
                        <CardActionArea>
                            <div className='hidden'>
                                <CardMedia
                                    component="img"
                                    height="750"
                                    image="./images/banner01-prata.jpg"
                                    alt="joias"
                                    className='zoom-in'
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" align="center">
                                    Brilho e Sofisticação ao Seu Alcance
                                </Typography>
                                <Typography className='legenda' variant="body2" color="text.secondary" align="center">
                                    Explore Nossa Coleção de Joias e Semi Joias Únicas e Transforme Cada Momento em Algo Especial. Desperte Seu Estilo com Peças Exclusivas!
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" href='https://wa.me/5522997993056' target="_blank">
                                Quero Revender
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Card >
                        <CardActionArea >
                            <div className='hidden'>
                                <CardMedia
                                    component="img"
                                    height="750"
                                    image="./images/banner02-prata.jpg"
                                    alt="joias"
                                    className='zoom-in'
                                />
                            </div>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div" align="center"> 
                                    Descubra a Elegância em Cada Detalhe
                                </Typography>
                                <Typography className='legenda' variant="body2" color="text.secondary" align="center">
                                    Joias Exclusivas que Encantam e Valorizam Seu Estilo. Encontre a Peça Perfeita para Brilhar em Qualquer Ocasião!
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary" href='https://wa.me/5522997993056' target="_blank" >
                                Quero Revender
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}