import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';

function Slider() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Row className="justify-content-md-center mb">
            <Col xs={12} md={12}>
                <Row>
                    <Carousel activeIndex={index} onSelect={handleSelect}>
                        <Carousel.Item>
                            <Image src="./images/slider02-prata.png" fluid />
                            <Carousel.Caption className='bg-legenda'>
                                <h3>Louzan Joias</h3>
                                <p className='legenda'>Peças únicas e exclusivas. Invista na Revenda de Joias!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item>
                            <Image src="./images/slider03-prata.jpg" fluid />
                            <Carousel.Caption className='bg-legenda'>
                                <h3>Encante e Lucre</h3>
                                <p className='legenda'>Encontre a Peça Perfeita para Brilhar em Qualquer Ocasião!</p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </Row>
            </Col>
        </Row>
    );
}

export default Slider;