import React, { useEffect, useContext, useState } from "react";
import validations from "../../classes/validations";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { BsCart } from "react-icons/bs";
import "./exclusive.css";
import Products from "./products";
import Cart from "../../components/cart/cart";
import AppContext from "../../context/AppContext";
import { ToastContainer } from "react-toastify";
import exclusive from "../../classes/exclusive";

const Exclusive = () => {
  const validation = new validations();
  const exclusiveClass = new exclusive();
  const [valor, setValor] = useState(0);
  const [valor2, setValor2] = useState(0);
  const [valor3, setValor3] = useState(0);
  const [userLimits, setUserLimits] = useState([]);
  const { cartItems, setCartItems, isCartVisible, setIsCartVisible } =
    useContext(AppContext);
  useEffect(() => {
    checkLogin();
    getCartItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkLogin = async () => {
    await validation.checkLogin();
    if (!validation.userIsValid) {
      window.location.replace(`${process.env.REACT_APP_SITE_URL}login`);
    }
    await exclusiveClass.getLimits();
    setValor3(localStorage.getItem("LOUZAN_limitValue"));
    setValor2(exclusiveClass.limits[1].limitValue);
    setValor(localStorage.getItem("LOUZAN_limitQuant"));
    await exclusiveClass.getUserLimits();
    setUserLimits(exclusiveClass.userLimits);
  };
  const getCartItems = async () => {
    await exclusiveClass.getCartItems();
    if (exclusiveClass.cartItems) {
      setCartItems(exclusiveClass.cartItems);
    }
  };
  return (
    <Container className="padding-b">
      <Button
        className="btn-list"
        onClick={() => setIsCartVisible(!isCartVisible)}
      >
        <BsCart />
        {cartItems.length > 0 && (
          <span className="list-number">{cartItems.length}</span>
        )}
      </Button>
      <Products valor={valor} valor2={valor2} valor3={valor3} userLimits={userLimits} />
      <Cart />
      <ToastContainer autoClose={3000} position="top-right" />
    </Container>
  );
};

export default Exclusive;
