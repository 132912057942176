import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, ptBR } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { Box, useTheme } from "@mui/material";
import exclusive from "../../classes/exclusive";
import "./config.css";
import Container from "react-bootstrap/Container";
import { ToastContainer } from "react-toastify";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import EditSize from "./editsize";
import CreateSize from "./addsize";

export default function Sizes() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const exclusiveClass = new exclusive();
  const [sizes, setSizes] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [size, setSize] = useState([]);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  useEffect(() => {
    getSizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSizes = async () => {
    await exclusiveClass.getSizess();
    setSizes(exclusiveClass.sizes);
  };
  const columns = [
    {
      field: "icone",
      headerName: "",
      renderCell: ({ row: { sizeId } }) => {
        return (
          <>
            <Button
              variant="info"
              className="pointer"
              onClick={() => handleEdit(sizes, sizeId)}
            >
              Modificar
            </Button>
          </>
        );
      },
      width: 140,
    },
    { field: "sizeValue", headerName: "Tamanho", width: 200 },
  ];
  const handleEdit = (sizes, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
        sizes.find((row) => row.sizeId === id)
    );
    setSize(selectedRowsData[0]);
    setShow(true);
  };
  const handleCreate = () => {
    setShow2(true);
  };
  return (
    <Container className="padding-bottom">
      <Row className="justify-content-md-left margin-b">
        <Col md="auto">
          <Button variant="primary" onClick={handleCreate}>
            Novo Tamanho
          </Button>
        </Col>
      </Row>
      <Grid
        className="sombra"
        m="20px 0 0 0"
        p="10px"
        xs={12}
        backgroundColor={colors.primary[100]}
        sx={{
          width: 590,
        }}
      >
        <Box
          m="8px 0 0 0"
          width="100%"
          maxWidth={580}
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
          }}
        >
          <DataGrid
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            rows={sizes}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.sizeValue}
          />
        </Box>
      </Grid>
      {show && (
        <EditSize
          setShow={setShow}
          show={show}
          getSizes={getSizes}
          size={size}
        />
      )}
      {show2 && (
        <CreateSize
          setShow3={setShow2}
          show3={show2}
          getSizes={getSizes}
        />
      )}
      <ToastContainer autoClose={3000} position="top-right" />
    </Container>
  );
}
