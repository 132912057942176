import React, { useContext } from "react";
import "./search.css";
import { FaSearch } from "react-icons/fa";
import AppContext from "../../context/AppContext";

export default function Search({ getProductsWithName }) {
  const { name, setName } = useContext(AppContext);
  const handleSend = (e) => {
    getProductsWithName();
  };
  return (
    <>
      <div className="input-wrapper">
        <FaSearch id="search-icon" onClick={handleSend} />
        <input
          placeholder="Nome do produto..."
          value={name}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.keyCode === 13) handleSend(e);
          }}
          className="input-search"
          enterKeyHint="done"
        />
      </div>
    </>
  );
}
