import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import exclusive from "../../classes/exclusive";
import Loading from "../../components/loading/loading";
import Button from "react-bootstrap/Button";
import { ToastContainer } from "react-toastify";
import ChangeProduct from "./changeProduct";
import CreateProduct from "./addproduct";
import validations from "../../classes/validations";
import "./cadastro.css";

export default function Cadastro() {
  const validationClass = new validations();
  const exclusiveClass = new exclusive();
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState();
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [colorss, setColorss] = useState([]);
  const [sizes, setSizes] = useState([]);
  useEffect(() => {
    checkAdminAcess();
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const checkAdminAcess = async () => {
    await validationClass.checkAdmin();
  };
  const getProducts = async () => {
    await exclusiveClass.getAllProducts();
    setProducts(exclusiveClass.allProducts);
    await exclusiveClass.getCategories();
    setCategories(exclusiveClass.categories);
    await exclusiveClass.getSizes();
    setSizes(exclusiveClass.sizes);
    await exclusiveClass.getColors();
    setColorss(exclusiveClass.colors);
    setLoading(false);
  };
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "icone",
      headerName: "",
      renderCell: ({ row: { productId } }) => {
        return (
          <>
            <Button
              variant="info"
              className="pointer"
              onClick={() => handleEdit(products, productId)}
            >
              Modificar
            </Button>
          </>
        );
      },
      width: 140,
    },
    { field: "productName", headerName: "Nome do Produto", width: 400 },
    {
      field: "productPrice",
      headerName: "Preço",
      width: 180,
      valueFormatter: (params) =>
        params.value > 0
          ? params.value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          : "R$ 0,00",
    },
    { field: "productQuant", headerName: "Quantidade", width: 180 },
    { field: "productType", headerName: "Categoria", width: 180 },
  ];
  const handleEdit = (produtos, id) => {
    const id2 = [id];
    const selectedRowsData = id2.map((id) =>
      produtos.find((row) => row.productId === id)
    );
    setProduct(selectedRowsData[0]);
    setShow(true);
  };
  const handleCreate = () => {
    setShow2(true);
  }
  return (
    (loading && <Loading />) || (
      <Container className="padding-b">
        <Box m="20px">
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
            m="10px 0"
          >
            <Header
              title="Administração de Produtos"
              subtitle="Crei, edite e elimine Produtos aqui."
            />
          </Box>
        </Box>
        <Grid
          className="sombra"
          m="20px 0 0 0"
          p="10px"
          xs={12}
          backgroundColor={colors.primary[100]}
        >
          <Button variant="primary" onClick={handleCreate}>Criar Produto</Button>
          <Box
            m="8px 0 0 0"
            width="100%"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
            }}
          >
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={products}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              getRowId={(row) => row.productId}
            />
          </Box>
        </Grid>
        {show && (
          <ChangeProduct
            show={show}
            setShow={setShow}
            product={product}
            getProducts={getProducts}
            categories={categories}
            sizess={sizes}
            colorss={colorss}
          />
        )}
        {show2 && (
          <CreateProduct
            show2={show2}
            setShow2={setShow2}
            getProducts={getProducts}
            categories={categories}
            sizess={sizes}
            colorss={colorss}
          />
        )}
        <ToastContainer autoClose={3000} position="top-right" />
      </Container>
    )
  );
}
