// import Container from '@mui/material/Container';
import Container from 'react-bootstrap/Container';
import Grid from "@mui/material/Unstable_Grid2";
import { Box, useTheme } from "@mui/material";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { tokens } from "../../theme";
import Button from 'react-bootstrap/Button';
import validations from '../../classes/validations';
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { ToastContainer } from "react-toastify";
import './login.css'

const Login = () => {
    const [email, setEmail] = useState(null);
    const [password, setSenha] = useState(null);
    const [message, setMessage] = useState();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const validation = new validations();
    useEffect(() => {
        checkLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const handleSend = async () => {
        if (!email) { return setMessage("Preencha o campo 'Email'") }
        if (!password) { return setMessage("Preencha o campo 'Senha'"); }
        const mensagem = await validation.tryLogin(email, password);
        if (mensagem) {
            setEmail('')
            setSenha('')
            return setMessage(mensagem);
        }
    }
    const checkLogin = async () => {
        validation.checkUser();
    }
    document.addEventListener("keypress", function (e) {
        if (e.key === 'Enter') {
            if (!email) { return setMessage("Preencha o campo 'Email'") }
            if (!password) { return setMessage("Preencha o campo 'Senha'"); }
            handleSend()
        }
    });
    const setEmail2 = (email) => {
        setMessage('');
        setEmail(email);
    }
    const pass = (senha) => {
        setMessage('');
        setSenha(senha);
    }
    return (
        <Container className='pd-bt'>
            <Box m="20px" >
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }} alignItems='center' justifyContent="center"  >
                    <Grid p="15px" className='sombra radius bg-login' xs={12} sm={12} md={6} lg={3} xl={3} backgroundColor={colors.primary[100]} >
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            m='50px 0 50px 0'
                        >
                            <img
                                className="avater-image"
                                alt="louzanlogo"
                                src="logo.png"
                            />
                        </Box>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Label>Email</Form.Label>
                                <Form.Control value={email} onChange={(e) => setEmail2(e.target.value)} maxLength='45' size="md" type="email" placeholder="Endereço de Email" />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} >
                                <Form.Label>Senha</Form.Label>
                                <Form.Control value={password} onChange={(e) => pass(e.target.value)} maxLength='10' size="md" type="password" placeholder="Senha" />
                            </Form.Group>
                        </Row>
                        {message ?
                            <Alert severity="info">
                                {message}
                            </Alert>
                            : ''
                        }
                        <Row className="mb-3 teste">
                            <Button onClick={handleSend} size="md" variant='secondary' >Acessar</Button>
                        </Row>
                    </Grid>
                </Grid>
            </Box>
            <ToastContainer autoClose={3000} position="bottom-left" />
        </Container>
    )
}

export default Login;