import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";
import ConfirmDecision from "./deletecolor";

export default function EditColor({
  show,
  setShow,
  getColors,
  colorr,
}) {
  const exclusiveClass = new exclusive();
  const [colorName, setColorName] = useState(colorr.colorName);
  const [show3, setShow3] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleSalve = async () => {
    if (!colorName) {
      return toast.warn("Preencher nome da Cor corretamente.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    await exclusiveClass.changeColor(colorName, colorr.colorId);
    if (exclusiveClass.colorIsChanged) {
      getColors();
      setShow(false);
      return toast.success("Cor alterada com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    toast.error("Erro ao modificar Cor. Tente novamente mais tarde.", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
    setShow(false);
  };
  const handleDelete = async () => {
    setShow3(true);
  };
  return (
    <>
      <Modal size="sm" centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Tamanho</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Tamanho</Form.Label>
              <Form.Control
                required
                value={colorName}
                maxLength={255}
                onChange={(e) => setColorName(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleDelete}>
            Deletar
          </Button>
          <Button variant="primary" onClick={handleSalve}>
            Salvar
          </Button>
        </Modal.Footer>
        {show3 && <ConfirmDecision show3={show3} setShow3={setShow3} setShow={setShow} color={colorr} getColors={getColors} />}
      </Modal>
    </>
  );
}
