import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import React, { useState, useEffect } from "react";
import "./menu.css";
import validations from "../classes/validations";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import NavDropdown from "react-bootstrap/NavDropdown";

function CollapsibleExample() {
  const [color, setColor] = useState(false);
  const validation = new validations();
  const [logado, setLogado] = useState(false);
  const [userName, setUserName] = useState();
  const [userFunction, setUserFunction] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    checkLogin();
  });
  const checkLogin = async () => {
    await validation.checkLogin();
    if (validation.userIsValid) {
      setUserName(localStorage.getItem("LOUZAN_userName"));
      const funcao = localStorage.getItem("LOUZAN_userFunction");
      setUserFunction(funcao === 'Administrador' ? true : false );
      setLogado(true);
    }
  };
  const changeColor = () => {
    if (window.scrollY >= 70) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);
  const handleLogin = (data) => {
    navigate(data);
  };
  const handleLogout = () => {
    localStorage.removeItem("LOUZAN_userId");
    localStorage.removeItem("LOUZAN_userName");
    localStorage.removeItem("LOUZAN_userEmail");
    localStorage.removeItem("LOUZAN_userKey");
    localStorage.removeItem("LOUZAN_userFunction");
    localStorage.removeItem("LOUZAN_token");
    window.location.replace(`${process.env.REACT_APP_SITE_URL}`);
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={color ? "menu-bg" : "bg-body-tertiary"}
      sticky={color ? "top" : ""}
    >
      <Container>
        <Navbar.Brand href="/">
          <img src="logo.png" width="150" height="50" alt="" />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="responsive-navbar-nav"
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className="tp-font" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="tp-font" href="/quemsomos">
              Quem Somos
            </Nav.Link>
            <Nav.Link className="tp-font" href="/contato">
              Contato
            </Nav.Link>
            {logado && (
              <Nav.Link className="tp-font" href="/exclusive">
                Produtos
              </Nav.Link>
            )}
            {logado && (
              <Nav.Link className="tp-font" href="/solicitacoes">
                Meus Pedidos
              </Nav.Link>
            )}
            {logado && userFunction && (
              <NavDropdown
                className="tp-font"
                title="Administração"
                id="collapsible-nav-dropdown"
              >
                <NavDropdown.Item className="tp-font" href="/pedidos">
                Administração de Pedidos
                </NavDropdown.Item>
                <NavDropdown.Item className="tp-font" href="/cadastro">
                  Administração de Peças
                </NavDropdown.Item>
                <NavDropdown.Item className="tp-font" href="/users">
                  Administração de Usuários
                </NavDropdown.Item>
                <NavDropdown.Item className="tp-font" href="/config">
                  Configurações
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            {logado ? (
              <Navbar.Text className="user-name tp-font">
                Bem-Vindo:{" "}
                <a className="user-name" href="/exclusive">
                  {userName}
                </a>
                <IconButton>
                  <LogoutIcon onClick={() => handleLogout()} />
                </IconButton>
              </Navbar.Text>
            ) : (
              <Navbar.Text className="user-name">
                <Button
                  onClick={(e) => handleLogin("/login")}
                  variant="primary"
                >
                  Área Exclusiva
                </Button>
              </Navbar.Text>
            )}
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
