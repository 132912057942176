import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function AddUser({ show3, setShow3, getUsers }) {
  const handleClose = () => setShow3(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [pass, setPass] = useState();
  const [admin, setAdmin] = useState();
  const [limit, setLimit] = useState();
  const [limitUnFormated, setLimitUnFormated] = useState(0);
  const [quant, setQuant] = useState(30);
  const exclusiveClass = new exclusive();
  const handleChange = async () => {
    const key = createKey(25);
    const newUser = {
      name,
      email,
      limitUnFormated,
      quant,
      pass,
      key,
      admin: admin ? "Administrador" : "Revendedor",
    };
    await exclusiveClass.addUser(newUser);
    if (exclusiveClass.userIsCreated) {
      setShow3(false);
      getUsers();
      return toast.success("Usuário criado com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    // setShow3(false);
    return toast.error("Erro ao criar usuário. Tente novamente mais tarde.", {
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Slide,
    });
  };
  const handleClick = () => {
    if (admin) {
      setAdmin("");
    } else {
      setAdmin("Administrador");
    }
  };
  function createKey(tamanho) {
    var stringAleatoria = "";
    var caracteres =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < tamanho; i++) {
      stringAleatoria += caracteres.charAt(
        Math.floor(Math.random() * caracteres.length)
      );
    }
    return stringAleatoria;
  }
  const mascaraMoeda = (event) => {
    const onlyDigits = event
      .split("")
      .filter((s) => /\d/.test(s))
      .join("")
      .padStart(3, "0");
    const digitsFloat = onlyDigits.slice(0, -2) + "." + onlyDigits.slice(-2);
    setLimit(maskCurrency(digitsFloat));
    let valor2 = maskCurrency(digitsFloat);
    valor2 = valor2.replace("R$", "");
    valor2 = valor2.replace(".", "");
    valor2 = valor2.replace(",", ".");
    valor2 = valor2.trim();
    setLimitUnFormated(valor2);
  };

  function maskCurrency(valor, locale = "pt-BR", currency = "BRL") {
    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency,
    }).format(valor);
  }
  return (
    <>
      <Modal size="md" centered show={show3} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Criar Usuário</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Nome do Usuário</Form.Label>
              <Form.Control
                required
                value={name}
                maxLength={100}
                onChange={(e) => setName(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                required
                value={email}
                maxLength={100}
                onChange={(e) => setEmail(e.target.value)}
                size="md"
                type="text"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Senha do Usuário</Form.Label>
              <Form.Control
                required
                value={pass}
                maxLength={10}
                onChange={(e) => setPass(e.target.value)}
                size="md"
                type="password"
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Label>Valor limite por Solicitação</Form.Label>
            <Col xs={6} md={4}>
              <Form.Group as={Col}>
                <Form.Control
                  required
                  value={limit}
                  maxLength={15}
                  onChange={(e) => mascaraMoeda(e.target.value)}
                  size="md"
                  type="text"
                  placeholder="R$0,00"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Label>Número de Peças por Solicitação</Form.Label>
            <Col xs={6} md={4}>
              <Form.Group as={Col}>
                <Form.Control
                  required
                  value={quant}
                  onChange={(e) => setQuant(e.target.value)}
                  size="md"
                  type="number"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Check // prettier-ignore
                type="checkbox"
                id="adm"
                label="Usuário Administrador"
                checked={admin ? true : ""}
                onClick={handleClick}
              />
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChange}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
