import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import Menu from './components/menu';
import Footer from './components/footer';
import Exclusive from './pages/exclusive/exclusive';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Login from './pages/login/login';
import Provider from "./context/provider";
import 'react-toastify/dist/ReactToastify.css';
import Solicitacoes from './pages/solicitacoes/solicitacoes';
import Config from './pages/config/config';
import { Users } from './pages/users/users';
import Cadastro from './pages/products/cadastro';
import AboutUs from './pages/aboutUs/aboutus';
import Contact from './pages/contact/contact';
import Pedidos from './pages/pedidos/pedidos';

const App = () => {

  return (
    <div className='position'>
      <Provider>
        <Menu />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/quemsomos" element={<AboutUs />} />
          <Route path="/contato" element={<Contact />} />
          <Route path="/exclusive" element={<Exclusive />} />
          <Route path="/solicitacoes" element={<Solicitacoes />} />
          <Route path="/config" element={<Config />} />
          <Route path="/users" element={<Users />} />
          <Route path="/cadastro" element={<Cadastro />} />
          <Route path="/pedidos" element={<Pedidos />} />
        </Routes>
        <Footer />
      </Provider>
    </div>
  );
};

export default App;
