import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGrid, GridToolbar, ptBR } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import exclusive from "../../classes/exclusive";
import moment from "moment";
import "./solicitacoes.css";
import Loading from "../../components/loading/loading";

export default function Solicitacoes() {
  const exclusiveClass = new exclusive();
  const [solicitacoes, setSolicitacoes] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getSolicitacoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getSolicitacoes = async () => {
    await exclusiveClass.getSolicitacoes();
    setSolicitacoes(exclusiveClass.solicitacoes);
    setLoading(false);
  };
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "userName", headerName: "Solicitante", width: 280 },
    {
      field: "date",
      headerName: "Data da Solicitação",
      width: 150,
      valueFormatter: (params) => moment(params?.value).format("DD/MM/YYYY"),
    },
    { field: "hour", headerName: "Hora da Solicitação", width: 140 },
    { field: "status", headerName: "Status", width: 120 },
    {
      field: "valor",
      headerName: "Valor Total",
      width: 140,
      valueFormatter: (params) =>
        params.value > 1
          ? params.value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          : "R$ 0,00",
    },
  ];
  return (
    (loading && <Loading />) || (
      <Container className="padding-b">
        <Box m="20px">
          <Box
            display={smScreen ? "flex" : "block"}
            flexDirection={smScreen ? "row" : "column"}
            justifyContent={smScreen ? "space-between" : "start"}
            alignItems={smScreen ? "center" : "start"}
            m="10px 0"
          >
            <Header
              title="Minhas Solicitações"
              subtitle="Lista de solicitações realizadas"
            />
          </Box>
        </Box>
        <Grid
          className="sombra"
          m="20px 0 0 0"
          p="10px"
          xs={12}
          backgroundColor={colors.primary[100]}
        >
          <Box
            m="8px 0 0 0"
            width="100%"
            height="75vh"
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
            }}
          >
            <DataGrid
              localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
              rows={solicitacoes}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              getRowId={(row) => row.idHeader}
            />
          </Box>
        </Grid>
      </Container>
    )
  );
}
