import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";
import Select from "react-select";

export default function ChangeCategoryLimit({
  show2,
  setShow2,
  getLimits,
  users,
  categoria,
}) {
  const exclusiveClass = new exclusive();
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function modify(obj, newObj) {
    Object.keys(obj).forEach(function (key) {
      delete obj[key];
    });

    Object.keys(newObj).forEach(function (key) {
      obj[key] = newObj[key];
    });
  }
  const categoria2 = {};
  modify(categoria2, categoria);

  const getCategories = async () => {
    await exclusiveClass.getCategories();
    setUserName(categoria.userName);
    setCategory(categoria.category);
    setUserId(categoria.userId);
    const id = [categoria.userName];
    const selectedRowsData2 = id.map((user) =>
        users.find((row) => row.userName === user)
      );
    setDefaultUserName(selectedRowsData2);
    setCategories(changeData2(exclusiveClass.categories));
    const id2 = [categoria.category];
    const selectedRowsData = id2.map((categoria) =>
      exclusiveClass.categories.find((row) => row.typeName === categoria)
    );
    setDefaultCategory(selectedRowsData[0]);
  };
  const handleClose = () => setShow2(false);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState();
  const [defaultCategory, setDefaultCategory] = useState();
  const [userName, setUserName] = useState();
  const [defaultUserName, setDefaultUserName] = useState();
  const [userId, setUserId] = useState();
  const [quant, setQuant] = useState(categoria.limitt);
  // eslint-disable-next-line no-unused-vars
  const [isClearable, setIsClearable] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isSearchable, setIsSearchable] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isDisabled, setIsDisabled] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isRtl, setIsRtl] = useState(false);
  const changeData = (data) => {
    for (var linha of data) {
      Object.defineProperty(linha, "label", {
        value: linha.userName,
      });
    }
    return data;
  };
  const changeData2 = (data) => {
    for (var linha of data) {
      Object.defineProperty(linha, "label", {
        value: linha.typeName,
      });
    }
    return data;
  };
  const usersName = changeData(users);
  const setUserName2 = (e) => {
    if (!e) {
      setUserName("");
      setDefaultUserName('');
      setUserId('');
    } else {
      setUserName(e.userName);
      setUserId(e.userId);
      setDefaultUserName(e);
    }
  };
  const changeCategory = (e) => {
    if (!e) {
      setCategory("");
      setDefaultCategory('')
    } else {
      setCategory(e.typeName);
      setDefaultCategory(e)
    }
  };
  const handleChange = async () => {
    if (!userName) {
      return toast.error("Selecione um Usuário.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (!category) {
      return toast.error("Selecione uma Categoria.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    if (quant <= 0 || !quant) {
      return toast.error("Insira um limite valido.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    const dados = {
      userId: userId,
      userName: userName ? userName : defaultUserName,
      category: category ? category : defaultCategory,
      limit: quant,
      id: categoria.id
    };
    await exclusiveClass.changeCategoryLimit(dados);
    if (exclusiveClass.categoryLimitIsChanged) {
      setShow2(false);
      getLimits();
      return toast.success("Dados salvos com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    } else {
      return toast.error(
        "Ocorreu um erro. Verifique se o limte já foi cadastrado ou tente novamente mais tarde.",
        {
          position: "top-right",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Slide,
        }
      );
    }
  };
  return (
    <>
      <Modal size="md" centered show={show2} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modificar Limite por Categoria</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridMarca">
              <Form.Label>Usuário</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                defaultValue=""
                name="userName"
                options={usersName}
                onChange={setUserName2}
                placeholder="Selecione"
                value={defaultUserName}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridMarca">
              <Form.Label>Categoria</Form.Label>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                defaultValue=""
                name="typeName"
                options={categories}
                onChange={changeCategory}
                placeholder="Selecione"
                value={defaultCategory}
              />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Label>Limite de Peças</Form.Label>
            <Col xs={6} md={4}>
              <Form.Group as={Col}>
                <Form.Control
                  required
                  value={quant}
                  onChange={(e) => setQuant(e.target.value)}
                  size="md"
                  type="number"
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleChange}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
