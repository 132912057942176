import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import exclusive from "../../classes/exclusive";
import Loading from "../../components/loading/loading";
import { Slide, toast } from "react-toastify";

export default function Limits() {
  const exclusiveClass = new exclusive();
  // const [valor, setValor] = useState(0);
  const [valor2, setValor2] = useState(0);
  // const [valor3, setValor3] = useState(0);
  // const [productPriceUnFormated, setProductPriceUnFormated] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getLimits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getLimits = async () => {
    await exclusiveClass.getLimits();
    // setValor(exclusiveClass.limits[0].limitValue);
    setValor2(exclusiveClass.limits[1].limitValue);
    // setValor3(exclusiveClass.limits[2].limitValue2);
    // setProductPriceUnFormated(exclusiveClass.limits[2].limitValue2);
    setLoading(false);
  };
  const handleSave = async () => {
    await exclusiveClass.setLimits(valor2);
    if (exclusiveClass.limitIsSeted) {
      return toast.success("Limites configurados com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    getLimits();
    return toast.error(
      "Erro ao configurar limites. Tente novamente mais tarde.",
      {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };
  return (
    (loading && <Loading />) || (
      <>
        <Form>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="3">
              Limite de um mesmo item em uma solicitação:
            </Form.Label>
            <Col sm="2">
              <Form.Control
                type="number"
                value={valor2}
                onChange={(e) => setValor2(e.target.value)}
              />
            </Col>
          </Form.Group>
        </Form>
        <Row className="justify-content-md-left mb">
          <Col md="auto">
            <Button variant="primary" onClick={handleSave}>
              Salvar Limites
            </Button>
          </Col>
        </Row>
      </>
    )
  );
}
