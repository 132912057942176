import React from "react";
import Container from "react-bootstrap/Container";
import Grid from "@mui/material/Unstable_Grid2";
import "./contact.css";

export default function Contact() {
  return (
    <Container className="padding-b">
      <div className="center">
        <h1>Entre em contato conosco!</h1>
      </div>
      <div>
        <Grid container spacing={2}>
          <Grid
            xs='auto'
            sm={4}
            md={4}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <div>
              <div>
                <img src="whatss.png" alt="whatsapp" className="whats" />
              </div>
              <h5 className="nome" >WhatsApp</h5>
              <p>Tem alguma dúvida?</p>
              <p className="info">22 99799-3056</p>
            </div>
          </Grid>
          <Grid
            xs='auto'
            sm={4}
            md={4}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <div>
              <div>
                <img src="insta-svg.png" alt="insta" className="insta" />
              </div>
              <h5 className="nome-insta">Instagram</h5>
              <p>Envie uma mensagem!</p>
              <p className="info">@louzanjoias</p>
            </div>
          </Grid>
          <Grid
            xs='auto'
            sm={4}
            md={4}
            justifyContent="center"
            display="flex"
            alignItems="center"
          >
            <div>
              <div>
                <img src="email.jpg" alt="whatsapp" className="email" />
              </div>
              <h5 className="nome-email">E-mail</h5>
              <p>Tem alguma dúvida?</p>
              <p className="info">louzanjoias@gmail.com</p>
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
