import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import exclusive from "../../classes/exclusive";
import { Slide, toast } from "react-toastify";

export default function DeleteCategoryLimit({ show3, setShow3, categoria, getLimits }) {
  const exclusiveClass = new exclusive();
  const handleClose = () => setShow3(false);
  const handleDelete = async () => {
    await exclusiveClass.deleteCategoryLimit(categoria.id);
    if (exclusiveClass.categoryLimitIsDeleted) {
      setShow3(false);
      getLimits();
      return toast.success("Limite excluido com sucesso.", {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      });
    }
    setShow3(false);
    return toast.error(
      "Erro ao excluir configuração de Limite. Tente novamente mais tarde ou entre em contato com o administrador",
      {
        position: "top-right",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Slide,
      }
    );
  };
  return (
    <div>
      <Modal size="md" show={show3} onHide={handleClose}>
        <Modal.Header closeButton >
          <Modal.Title>Deletar Limite!</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <p>Deseja realmente EXCLUIR a configuração de limite do Usuário "{categoria.userName}"?</p>
          <p>Uma vez confirmada, esta ação não poderá ser desfeita.</p>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="primary" onClick={handleDelete}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
